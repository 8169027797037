import React from 'react';
import './Footer.css';
import { BsArrowRightCircle } from "react-icons/bs";


const Footer = () => {
  return (
   
    <footer className='Footer-container'>
        <div className='footer-left'>
                <img src="https://logos-world.net/imageup/Mercedes_Benz/Mercedes_Benz-Logo-PNG7.png" alt="mercedes benz logo" />
                <h3>All Copyright is deserved by me in 2024</h3>
        </div>

        <div className='footer-right'>

            <div className='input-btn'>
                <input type="text" placeholder=' your email '/>
                <button type='button'>Contact</button>
            </div>

            <div className="header-footer">

                <div className="arrow">
            <BsArrowRightCircle  className='arw'/><a href="#">Home</a>
                </div>

                <div className="arrow">
            <BsArrowRightCircle className='arw' /><a href="#">Sponsors</a>
                </div>

                <div className="arrow">
            <BsArrowRightCircle className='arw' /><a href="#">Value</a>
                </div>

                <div className="arrow">
            <BsArrowRightCircle className='arw' /><a href="#">Contact Us</a>
                </div>

            </div>

        </div>

    </footer>

  )
}

export default Footer
