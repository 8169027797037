import React from 'react';
import './Product.css';
import 'swiper/css';
import data from '../Product/data.json';
import { Swiper,SwiperSlide, useSwiper } from 'swiper/react';



const Product = () => {
  return (
    <div className='Product-container'>

        <div className="Product-text">
            <h1 >PRODUCTS</h1>
            <h4>Our Products:</h4>
        </div>
            
        <Swiper 
             spaceBetween={50}
             slidesPerView={4}
             id='swPr'
             breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                450: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
        >
            <SliderButtons />
            {
                data.map((card, i)=>(
                    <SwiperSlide key={i} >
                        <div className='flexColStart r-card'>
                            <img src={card.image} alt="image" />
                            <h3>{card.name}</h3>
                            <p>{card.detail}</p>
                            <button type='submit' className='proBtn'>Now More</button>
                        </div>

                    </SwiperSlide>
                ))
            }
        </Swiper>
      
    </div>
  )
}

export default Product;



const SliderButtons = () => {
    const swiper = useSwiper();
    return(
        <div className="r-buttons">
            <button onClick={()=>swiper.slidePrev()}> &lt;</button>
            <button onClick={()=>swiper.slideNext()}> &gt; </button>
        </div>
    )
}


