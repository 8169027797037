import React from 'react';
import { Accordion, AccordionItem, AccordionItemHeading,AccordionItemButton,
  AccordionItemPanel} from 'react-accessible-accordion';
import { HiShieldCheck } from "react-icons/hi";
import { MdCancel, MdAnalytics } from "react-icons/md";
import './accordion.css';
import {MdOutlineArrowDropDown} from 'react-icons/md';



const accordion = () => {
  return (
    <div className='accordion-container'>
      
      <div className="accordion-left">
           <img src="https://i.pinimg.com/736x/6c/0c/01/6c0c0102a37442765304d1d4730e591b.jpg" alt="zabi" />
      </div>



      <div className="accordion-right">
                <div className='spans'>
                <span className='one'>Our Value</span><br />
                <span className='two'>Value We Give to you</span><br />
                <span className='tree'>We always ready to help by providing the best services for you. </span> <br/>
                </div>

                <Accordion 
                className='accordion'
                allowMultipleExpanded={false}
                preExpanded={[0]}
                >
                   {
                        data.map((item, i)=>{
                           
                            return(
                                <AccordionItem className='accordionItem' key={i} uuid={i}>
                                     <AccordionItemHeading>
                                        <AccordionItemButton className='flexCenter  accordionButton'>
                                           <div className="flexCenter icon">{item.icon}</div>
                                            <span className="primaryText">
                                               {item.heading} 
                                            </span>
                                            <div className="flexCenter icon">
                                                <MdOutlineArrowDropDown size={25} />
                                            </div>
                                        </AccordionItemButton>
                                     </AccordionItemHeading>

                                     <AccordionItemPanel>
                                        <p className='secondaryText'>{item.detail}</p>
                                     </AccordionItemPanel>
                                </AccordionItem>
                            )
                        })
                    }
                 
                </Accordion>











      </div>
      
    </div>
  )
}

export default accordion;

const data = [
  {
    icon: <HiShieldCheck/>,
    heading: "Best interest rates on the market",
    detail:
      "Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.",
  },
  {
    icon: <MdCancel />,
    heading: "Prevent unstable prices",
    detail:
      "Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.",
  },
  {
    icon: <MdAnalytics />,
    heading: "Best price on the market",
    detail:
      "Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.",
  }
];
