import React from 'react';
import './Sponsor.css';



const Sponsor = () => {
  return (
    <div className='Sponsor-container'>

        <div className="center-spo">
            <div className="Sponsor-name">
                {/* <h1>SPONSORS</h1> */}
                <p>We Sponsor By:</p>
            </div>
            </div>


            <div className="Sponsor-elements">
                <img src="./img/Epson_(2).png" alt="" />
                <img src="./img/Puma.png" alt="" />
                <img src="./img/Monster.png" alt="" />
                <img src="./img/Tommy.png" alt="" />
                <img src="./img/UBS.png" alt="" />
            </div>
           
    </div>
  )
}

export default Sponsor;
