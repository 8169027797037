import React from 'react';
import './Hero.css';
import CountUp from "react-countup";


const Hero = () => {
  return (
    <div className='Hero-container'>
    <div className="Hero-elements">
      <h1>Im MERCEDES</h1>
      <h3>Best Company In The World</h3>
      <h5>you want to be  <span className='best'> the best</span></h5>
      <button type='button' className='btn'>Click Here</button>

        <div className="countupall">

                      <div className="countup">

                        <span>
                          <span className='counupNum'>+</span>
                          <CountUp start={200} end={300} duration={5} className='counupNum'/>
                        </span>

                        <span className='HeroT'>
                          Premium <br />Product
                        </span>

                      </div>

                          <div className="countup">

                            <span>
                              <span className='counupNum'>+</span>
                              <CountUp start={100} end={200} duration={7} className='counupNum'/><span className='counupNum'>M</span>
                            </span>

                            <span className='HeroT'>
                              Happy<br />Customer
                            </span>

                          </div>

                              <div className="countup">

                                <span>
                                  <span className='counupNum'>+</span>
                                  <CountUp start={200} end={753} duration={5} className='counupNum'/><span className='counupNum'>M</span>
                                </span>

                                <span className='HeroT'>
                                   Trust<br />Client
                                </span>

                              </div>
        </div>

    </div>
    </div>
  );
}

export default Hero ;
