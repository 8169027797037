import React from 'react';
import './App.css';
import Header from './component/Header/Header';
import Hero from './component/Hero/Hero';
import Sponsor from './component/Sponsor/Sponsor';
import Product from './component/Product/Product';
import Value from './component/Accordion/accordion';
import Contact from './component/Contact/contact';
import Footer from './component/Foter/Footer';



function App() {
  return (
   <>
   <Header  />
   <Hero />
   <Sponsor />
   <Product />
   <Value />
   <Contact />
   <Footer />
   </>
  );
}

export default App;
