import React, { useRef } from 'react';
import './contact.css';
import emailjs from '@emailjs/browser';

const Contact = () => { // Changed "contact" to "Contact"

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0tdlsin', 'template_y9lv5zx', form.current, {
      publicKey: '4-JCccenKvksEkSOM',
    })
    .then(
      () => {
        console.log('SUCCESS!'); 
      },
      (error) => {
        console.log('FAILED...', error.text);
      },
    );
    form.current.reset();
  }


  return (
    <div className='contact-container'>
      <div className="ContactUs">
        <h1>Contact Us</h1>
      </div>

      <div className="ForBr">
        <form className="dv" onSubmit={sendEmail} ref={form}>
          <div className="fullName">
            <label htmlFor="name"> Full Name</label><br />
            <input type="text" id='name' placeholder='your full name' name='user_name' />
          </div>

          <div className="Num">
            <label htmlFor="num"> Number</label><br />
            <input type="number" id='num' placeholder='+212 ' name='user_number' />
          </div>

          <div className="Email">
            <label htmlFor="email"> Email</label><br />
            <input type="email" id='email' placeholder='example@gmail.com' name='user_email' />
          </div>

          <div className="Message">
            <label htmlFor="msg"> Message</label><br />
            <textarea type="text" id='msg' placeholder='your message' rows={7} cols={75} name='message'  />
          </div>

          <div className="SubmitBtn" >
            <button type='submit' value="Send">Send</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact; // Changed "contact" to "Contact"
