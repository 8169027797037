import React ,{useState} from 'react';
import './Header.css';
import { FaBars } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";


const Header = () => {

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };



  return (
    <div className='container'>

      <div className='header-image'>
        <img src='img/pngegg.png'  alt="" />
      </div>

      <div className="header-elements">

        <div className='elements' id={menuOpen ? "open" : "close" }>
        <a href='#' id='active'>HOME</a>
        <a href='#'> SPONSOR </a>
        <a href='#'> PRODUCT </a>
        <a href='#'> VALUE </a>

        <button type='button' className='btn'> CONTACT US </button>
        </div>
      </div>

      <button className="toggle-btn" onClick={toggleMenu}>
        {menuOpen ? <FaXmark /> : <FaBars />}
      </button>

    </div>
  )
}

export default Header;
